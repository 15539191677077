<template>
<div>
  <div class="screen-bg1">
    <div class="timer-content">{{timer}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Screen',
  data () {
    return {
      timer: ''
    }
  },
  mounted () {
    this.getLangDate()
  },
  methods: {
    getLangDate () {
      const dateObj = new Date() // 表示当前系统时间的Date对象
      const year = dateObj.getFullYear() // 当前系统时间的完整年份值
      let month = dateObj.getMonth() + 1 // 当前系统时间的月份值
      let date = dateObj.getDate() // 当前系统时间的月份中的日
      const day = dateObj.getDay() // 当前系统时间中的星期值
      const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      const week = weeks[day] // 根据星期值，从数组中获取对应的星期字符串
      let hour = dateObj.getHours() // 当前系统时间的小时值
      let minute = dateObj.getMinutes() // 当前系统时间的分钟值
      let second = dateObj.getSeconds() // 当前系统时间的秒钟值
      // 如果月、日、小时、分、秒的值小于10，在前面补0
      if (month < 10) {
        month = '0' + month
      }
      if (date < 10) {
        date = '0' + date
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      if (second < 10) {
        second = '0' + second
      }
      const newDate = year + '年' + month + '月' + date + '日 ' + week + ' ' + hour + ':' + minute + ':' + second
      this.timer = newDate
      setTimeout(() => {
        this.getLangDate()
      }, 1000)// 每隔1秒重新调用一次该函数
    }
  }
}
</script>

<style scoped>
.timer-content{
  color: #4085BA;
  font-size: 20px;
  line-height: 60px;
  text-align: right;
  padding-right: 30px;
}
.screen-bg1{
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/terminal/screen-bg1.png");
}
</style>
